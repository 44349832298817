import {Controller} from "../controller";

interface IDataLayerEvent {
    event: string;
    ga4_event_name?:string;
    ga4_action_name?:string;
    ga4_tag_name?:string;
}

class DataLayer extends Controller{
    public static push(data:IDataLayerEvent):void {
        (window as any)['dataLayer'].push(data);
    }

    public static getGA4Event():{ event: string, ga4_event_name: string; ga4_action_name: string; ga4_tag_name: string;} {
        return {
            event: 'ga4_event',
            ga4_event_name: '',
            ga4_action_name:'',
            ga4_tag_name:''
        };
    }

    public static sendGA4Event(event_name: string, action_name: string, tag_name: string):void {
        const event:IDataLayerEvent = this.getGA4Event();
        if (event_name != null) {
            event.ga4_event_name = event_name;
        }
        if (action_name != null) {
            event.ga4_action_name = action_name;
        }
        if (tag_name != null) {
            event.ga4_tag_name = tag_name;
        }
        this.push(event);
    }
}

export {DataLayer, IDataLayerEvent};
