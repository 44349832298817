export interface IController {
    build: ()=>Promise<void>;
}

export abstract class Controller {
    /* STATIC */
    public static async build(): Promise<void> {
    }

    /* INSTANCE */
}
