// NO EDITAR A MANO
import type {Error} from "./error";
import type {Footer} from "./footer";
import type {Home} from "./home";
import type {Idiomas} from "./idiomas";
import type {Landings} from "./landings";
import type {Menu} from "./menu";
import {checkClean} from "./langs";

export interface ModuloLoader {
    error: Promise<Error>;
    footer: Promise<Footer>;
    home: Promise<Home>;
    idiomas: Promise<Idiomas>;
    landings: Promise<Landings>;
    menu: Promise<Menu>;
}

const MAPPING: Record<string, string> = {};

export default (lang: string, defecto: string): Promise<ModuloLoader> => import(/* webpackChunkName: "i18n/[request]" */ `i18n/.src/${checkClean(lang, defecto, MAPPING)}/`).then(module=> module.default);
