// NO EDITAR A MANO
export type Langs = "de" | "en" | "es" | "fr" | "it" | "pt" | "pt-BR";

export const SOPORTADOS: string[] = ["de", "en", "es", "fr", "it", "pt", "pt-BR"];

export function check(lang: string, defecto: string, mapping: Record<string, string> = {}): Langs {
    if (SOPORTADOS.indexOf(lang)<0) {
        if (lang.length>2) {
        lang = lang.substring(0, 2);
        if (SOPORTADOS.indexOf(lang)<0) {
            lang = defecto;
        }
    } else {
        lang = defecto;
    }
    }
    if (mapping[lang]!=undefined) {
        lang = mapping[lang];
    }

    return lang as Langs;
}

export const checkClean = (lang: string, defecto: string, mapping: Record<string, string> = {}): string => check(lang, defecto, mapping).replace("-", "");
