import {Controller} from "../controller";
import site from "../data/site";

export class ConfigCmp extends Controller {
    /* INSTANCE */
    public static override async build(): Promise<void> {

        const button_config_cmp = document.getElementById("configCMP") as HTMLAnchorElement|null;
        if (button_config_cmp != null) {
            button_config_cmp?.addEventListener("click",() => {
                (window as any)['__tcfapiprivate']('showCMP', null, function() {});
            })
        }
    }
}
