import {PromiseDelayed} from "services-comun/modules/utiles/promise";
import {error} from "services-comun/modules/browser/log";

import {IController} from "../controller";
import {ILoader} from "../loader";

class Init {
    /* STATIC */

    /* INSTANCE */

    private readonly modulos: IController[];
    private readonly onload: IController[];
    private running: boolean;

    public constructor() {
        this.modulos = [];
        this.onload = [];
        this.running = false;

        window.addEventListener("load", ()=>{
            this.ejecutarOnload();
        });
    }

    public addModulo(modulo: IController): void {
        this.modulos.push(modulo);
        this.initRunning();
    }

    public addLoader(loader: ILoader): void {
        loader.build()
            .then(async (loader)=>{
                for (const controller of loader.immediates) {
                    this.addModulo(controller);
                }
                for (const controller of loader.onload) {
                    this.addOnload(controller);
                }
            })
            .catch(async (err)=>{
                error("Error cargando Loader", err);
            });
    }

    public addOnload(modulo: IController): void {
        this.onload.push(modulo);
    }

    private initRunning(): void {
        if (this.running) {
            return;
        }
        this.running = true;
        this.initModulos().catch(async ()=>{
        }).then(async ()=>{
            await PromiseDelayed(10);
            this.running = false;
            if (this.modulos.length>0) {
                this.initRunning();
            }

        });
    }

    private async initModulos(): Promise<void> {
        if (this.modulos.length==0) {
            return;
        }

        const promesas: Promise<void>[] = [];
        for (const actual of this.modulos.splice(0)) {
            promesas.push(actual.build().catch(async ()=>{
                this.modulos.push(actual);
            }));
        }
        await Promise.all(promesas);
    }

    private ejecutarOnload(): void {
        this.modulos.push(...this.onload);
        this.initRunning();
    }
}

const init = new Init();

export default init;
