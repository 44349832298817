import type {} from "services-comun/modules/types.d.ts";

import '../utils/polyfills';
import 'www-templates/scss/componentes/privacity.scss';
// import '../data/test';
// import {CambioIdioma} from '../controllers/cambio-idioma';
import {Menu} from '../controllers/menu';
import {ConfigCmp} from "../controllers/config-cmp";
import {Pie} from '../controllers/pie';
// import {TestLoader} from "../loaders/test";
import init from "../utils/init";
// init.addModulo(CambioIdioma)
init.addModulo(Menu)
init.addModulo(ConfigCmp)
init.addModulo(Pie)

// init.addLoader(TestLoader);
